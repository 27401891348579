import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-7"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3 ",staticStyle:{"border-radius":"5px"},attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c('h2',{staticClass:"text-2xl font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1)],1),_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" Welkom! 👋🏻 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Scan je QR code, alsjeblieft. ")])]),_c(VCardText,[_c('qrcode-stream',{staticClass:"rounded",on:{"decode":_vm.onDecode}})],1),_c(VCardText,{staticClass:"d-flex align-center mt-2"},[_c(VDivider),_c('span',{staticClass:"mx-5"},[_vm._v("of")]),_c(VDivider)],1),_c(VCardText,[_c('a',{attrs:{"href":"https://sites.google.com/blokje.info/speelenleer/"}},[_c(VBtn,{attrs:{"color":"secondary","block":""}},[_vm._v(" Overslaan ")])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }