<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- Titel en logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
              style="border-radius: 5px;"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- Tekstjes -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welkom! 👋🏻
          </p>
          <p class="mb-2">
            Scan je QR code, alsjeblieft.
          </p>
        </v-card-text>

        <v-card-text>
          <qrcode-stream
            class="rounded"
            @decode="onDecode"
          ></qrcode-stream>
        </v-card-text>

        <!-- Divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">of</span>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-text>
          <a href="https://sites.google.com/blokje.info/speelenleer/">
            <v-btn
              color="secondary"
              block
            >
              Overslaan
            </v-btn>
          </a>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig'

// Icons
import {
  mdiEyeOffOutline, mdiEyeOutline,
} from '@mdi/js'

// QR
import { QrcodeStream } from 'vue-qrcode-reader'

// Axios
import axios from '@axios'

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    // themeConfig
    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,
    cookies: '',

    jwt_token: localStorage.getItem('accessToken'),

    // Icons
    icons: {
      mdiEyeOffOutline,
      mdiEyeOutline,
    },
  }),

  mounted() {
    this.sendMessage()
    this.cookies = this.getCookies()
  },

  methods: {
    // Method to get a cookie value by name
    getCookie(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()

      return null
    },

    getCookies() {
      const pairs = document.cookie.split(';')
      const cookies = {}
      for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split('=')
        cookies[(`${pair[0]}`).trim()] = unescape(pair.slice(1).join('='))
      }

      return cookies
    },

    setCookie(cookieName, cookieValue) {
      // Get the domain name
      const cookieDomain = window.location.hostname

      // Calculate expiration date
      const myDate = new Date()
      myDate.setMinutes(myDate.getMinutes() + 5)

      document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${cookieDomain};path=/`
    },

    // This function is executed when the QR code has been scanned
    async onDecode(qrToken) {
      // Check if the token starts with "BP_QR_TOKEN"
      if (!qrToken.startsWith('BP_QR_TOKEN')) {
        // Check if the qrToken is a link
        if (qrToken.startsWith('http')) {
          // Redirect to the link
          window.location.href = qrToken
        }
      }

      // Call the server to login
      const loginResponse = await axios.post('/auth/qr/login', {
        qrToken,
      })

      // If the login was succesfull, redirect to the finish page with the token as a parameter
      if (loginResponse.status === 200) {
        // Set the token in the cookie
        this.setCookie('bp_login_token', loginResponse.data.token)

        // Redirect to the finish page
        this.$router.push({
          name: 'plugin-login-finish',
          params: {
            id: loginResponse.data.token,
            authtype: 'plugin',
          },
        })
      } else {
        // Something went wrong...
        console.log('error!', loginResponse.status)
      }
    },

    async sendMessage() {
      console.log('sending message')
      const data = { type: 'BP_CLEAR_TOKEN' }
      setTimeout(() => {
        window.postMessage(data, '*')
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
